import { lv } from "date-fns/locale";
import setDefaultOptions from "date-fns/setDefaultOptions/index.js";
import dynamic from "next/dynamic";
import { NextPageWithLayout } from "@components/layouts/types";
import type { AppProps } from "next/app";
import "../styles/antd.customize.less";
import "../styles/index.css";

setDefaultOptions({ locale: lv });

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const CustomerAppContainer = dynamic(() =>
  import("@containers/app").then((mod) => mod.CustomerAppContainer),
);

const AdminAppContainer = dynamic(() =>
  import("@containers/app").then((mod) => mod.AdminAppContainer),
);

const PublicAppContainer = dynamic(() =>
  import("@containers/app").then((mod) => mod.PublicAppContainer),
);

const App = (props: AppPropsWithLayout) => {
  const path = props.router.asPath;
  if (path.startsWith("/admin")) {
    return <AdminAppContainer {...props} />;
  }
  if (
    path.startsWith("/sign-in") ||
    path.startsWith("/complete-registration")
  ) {
    return <PublicAppContainer {...props} />;
  }
  return <CustomerAppContainer {...props} />;
};

export default App;
